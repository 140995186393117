<template>
  <div class="choose-book-categoryId" v-loading="bookLoading">
    <el-select
      @change="handleCategoryIdChange"
      v-model="categoryId"
      placeholder="书籍分类"
      style="width: 150px"
      clearable
      filterable
    >
      <el-option
        :label="item.name"
        :value="item.id"
        :key="item.id"
        v-for="item in categoryList"
      >
      </el-option>
    </el-select>
    <div class="choose-book">
      <el-select
        v-model="bookId"
        filterable
        remote
        clearable
        reserve-keyword
        placeholder="请选择书籍"
        :remote-method="getBookList"
        @change="handleBookIdChange"
        style="width:150px"
      >
        <el-option
          v-for="item in bookList"
          :key="item.id"
          :label="item.bookName"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { getBookList } from "@/api/account";
import { getClassifyList } from "@/api/classify";
export default {
  name: "ChooseBook",
  props: ["bookInfo", "show"],
  data() {
    return {
      bookLoading: false,
      categoryId: null,
      bookName: null,
      bookId: null,
      bookList: [],
      isShowFilterBook: false,
      filterBookList: [],
      categoryList: [],
      options: [],
      isFlag: false,
      isCanHidden: false
    };
  },
  watch: {
    categoryId: {
      handler(newV) {
        if (!this.bookInfo) {
          this.getBookList();
        }
      }
    },
    show: {
      handler(newV) {
        if (newV) {
          this.$nextTick(() => {
            this.initBookList();
          });
        } else {
          this.bookId = null;
          this.categoryId = null;
          this.bookName = null;
          this.bookList = [];
        }
      },
      immediate: true
    }
  },
  methods: {
    handleBookIdChange(bookId) {
      this.$emit("chooseBookItem", {
        id: bookId ? bookId : 0,
        categoryId: this.categoryId ? this.categoryId : 0
      });
    },
    handleCategoryIdChange(val) {
      this.$emit("chooseBookItem", {
        id: this.bookId ? this.bookId : 0,
        categoryId: val ? val : 0
      });
    },
    getBookList(bookName, flag = false) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
        categoryId: this.categoryId === 0 ? null : this.categoryId
      }).then(res => {
        this.bookList = res.list;
        if (flag) {
          this.bookId = this.bookList[0].id;
        }
      });
    },
    async initBookList() {
      getClassifyList({
        type: 3
      }).then(async res => {
        res.unshift({
          id: 0,
          name: "所有",
          status: 1,
          type: 3
        });
        this.categoryList = res;
        // 如果是编辑模式
        if (this.bookInfo && this.bookInfo.bookCId) {
          this.categoryId = this.categoryList.find(
            item => item.id === this.bookInfo.bookCId
          ).id;
        }
        if (this.bookInfo && this.bookInfo.bookName) {
          this.getBookList(this.bookInfo.bookName, true);
        }
      });
    },
    initScroll() {}
  },
  beforeDestroy() {}
};
</script>
<style lang="scss" scoped>
.choose-book-categoryId {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .choose-book {
    position: relative;
    margin-left: 10px;
    font-size: 14px;
    .book-container {
      position: absolute;
      background: #fff;
      z-index: 1000;
      width: 200px;
      top: 40px;
      > p {
        padding: 0px 10px;
      }
      .book-wrapper {
        overflow: auto;
        border: 1px solid #ccc;
        border-radius: 4px;
        .ul-book-wrapper {
          li {
            label {
              box-sizing: border-box;
              padding: 12px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #24262e;
            }
            &:hover {
              background: #ebedf0;
            }
          }
        }
      }
    }
  }
}
</style>
