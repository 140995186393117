<template>
  <div
    class="landing-page-container box-shadow-light-grey padding-20 border-radius-6"
  >
    <div class="operation padding-bottom-20">
      <el-button @click="refreshData">刷新数据</el-button>
      <el-button class="" type="primary" @click="createSource"
        >新增素材</el-button
      >
      <el-button
        type="danger"
        plain
        :disabled="selectionList.length === 0"
        @click="delSelectSource"
        >批量删除</el-button
      >
      <el-input
        v-model="params.search"
        clearable
        placeholder="请输入素材信息"
        style="width: 240px; margin-right: 10px; margin-left: 10px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
      <select-dept-user :params="params" :canDisabled="false" />
      <el-select
        v-model="params.cId"
        style="margin-left: 10px; width: 100px"
        clearable
        placeholder="素材类型"
      >
        <el-option
          v-for="item in resourceCategoryList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <div style="display: inline-block; margin: 0 10px">
        <el-select
          v-model="params.categoryId"
          placeholder="书籍分类"
          style="width: 150px; margin-right: 10px"
          clearable
          filterable
        >
          <el-option
            :label="item.name"
            :value="item.id"
            :key="item.id"
            style="width: 150px"
            clearable
            filterable
            v-for="item in categoryList"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="params.bookId"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="请选择书籍"
          :remote-method="getBookList"
          style="width: 150px"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <el-date-picker
        v-model="timeRange"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right: 10px; width: 240px"
      >
      </el-date-picker>
      <el-button
        type="primary"
        @click="handleSearch"
        style="margin-right: 10px; margin-left: 0"
        class=""
        >搜索</el-button
      >
      <el-button
        type="info"
        style="margin-left: 0"
        @click="
          () => {
            resetData();
          }
        "
        class=""
        >重置</el-button
      >
    </div>
    <div class="custom-table" v-loading="loading">
      <el-table
        :data="dataList"
        style="width: 100%"
        height="calc(100vh - 260px)"
        ref="dataTable"
        @selection-change="
          (select) => {
            this.selectionList = select;
          }
        "
      >
        <el-table-column type="selection" width="55" fixed="left">
        </el-table-column>
        <el-table-column label="Id" prop="id" min-width="50"></el-table-column>
        <el-table-column label="书籍名称" min-width="120">
          <template slot-scope="scope">
            <div class="line-overflow" v-if="'book' in scope.row">
              {{ scope.row.book.bookName }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="书籍分类" min-width="80">
          <template slot-scope="scope">
            <div v-if="'bookCId' in scope.row">
              {{ getBookCId(scope.row.bookCId) }}
            </div>
            <div v-else>所有</div>
          </template>
        </el-table-column>
        <el-table-column label="用户名" min-width="100">
          <template slot-scope="scope">
            <div>
              {{ scope.row.adminUser.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="时间"
          prop="cdate"
          min-width="100"
        ></el-table-column>
        <el-table-column label="素材分类">
          <template slot-scope="scope">
            <div class="line-overflow">
              {{ getResourceName(scope.row.cId) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="素材" prop="img" width="150">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              title="原图"
              trigger="hover"
              width="700"
            >
              <div class="origin-img">
                <img
                  v-for="(item, index) in JSON.parse(scope.row.img)"
                  :src="item"
                  :key="index"
                />
              </div>
              <div class="thumbnail-img" slot="reference">
                <img :src="JSON.parse(scope.row.img)[0]" />
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="文案" min-width="200">
          <template slot-scope="scope">
            <template v-if="'content' in scope.row && scope.row.content">
              <el-popover placement="right" title="文案" trigger="hover">
                <div>
                  {{ scope.row.content }}
                </div>
                <div class="line-overflow" slot="reference">
                  {{ scope.row.content }}
                </div>
              </el-popover>
            </template>
            <template v-else>
              <span>--</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="想法" min-width="200">
          <template slot-scope="scope">
            <template v-if="'idea' in scope.row && scope.row.idea">
              <el-popover placement="right" title="想法" trigger="hover">
                <div>
                  {{ scope.row.idea }}
                </div>
                <div class="line-overflow" slot="reference">
                  {{ scope.row.idea }}
                </div>
              </el-popover>
            </template>
            <template v-else>
              <span>--</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button-small"
              @click="editSource(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="button-small"
              @click="deleteSelectedPage(`${scope.row.id}`)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <add-land-page
      :show.sync="showLandPage"
      :info="curInfo"
      @refresh="getData"
    ></add-land-page>
  </div>
</template>

<script>
import {
  getLandPageList,
  addLandPage,
  delLandPage,
  updateLandPage,
  getRemoteLinkLink,
} from "@/api/novelPut.js";
import SelectDeptUser from "@/components/SelectDeptUser";
import ChooseBook from "./children/choose-book.vue";
import { mapGetters, mapState, mapMutations } from "vuex";
import AddLandPage from "./children/add-land-page.vue";
import AddRemoteLink from "./children/add-remote-link";
import { getClassifyList } from "../../../api/classify";
import { getBookList } from "../../../api/account";
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      params: {},
      dataList: [],
      bookList: [],
      searchCId: "",
      searchBook: "",
      selectionList: [],
      categoryList: [],
      timeRange: [],
      resourceCategoryList: [],
      filterBookList: [],
      searchCategoryId: null,
      isShowFilterBook: false,
      searchBookLoading: false,
      searchStatus: "",
      search: "",
      showLandPage: false,
      curInfo: null,
      loading: false,
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    timeRange: {
      handler(newV) {
        if (newV && Array.isArray(newV) && newV.length > 0) {
          this.params.minDate = newV[0];
          this.params.maxDate = newV[1];
        } else {
          this.params.minDate = null;
          this.params.maxDate = null;
        }
      },
    },
  },
  methods: {
    initData() {
      getClassifyList({
        type: 3,
      }).then((res) => {
        this.categoryList = res;
      });
      getClassifyList({
        type: 5,
      }).then((res) => {
        this.resourceCategoryList = res;
      });
      this.getData();
    },
    getData() {
      this.loading = true;
      if ("adminUserId" in this.params) {
        this.params.userId = this.params.adminUserId;
        delete this.params.adminUserId;
      }
      getLandPageList({
        page: this.page,
        pageSize: this.pageSize,
        ...this.params,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBookList(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        categoryId: this.params.categoryId,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getBookCId(bookCId) {
      if (bookCId === 0) {
        return "所有";
      }
      return this.categoryList.find((item) => item.id === bookCId).name;
    },
    refreshData() {
      this.page = 1;
      this.getData();
    },
    createSource() {
      this.curInfo = null;
      this.showLandPage = true;
    },
    handleSearch() {
      this.getData();
    },
    delSelectSource() {
      let ids = this.selectionList.map((item) => item.id);
      this.deleteSelectedPage(ids.join(","));
    },
    getResourceName(cId) {
      if (!cId) return "所有";
      let target = this.resourceCategoryList.find((item) => item.id === cId);
      return target ? target.name : "--";
    },
    editSource(row) {
      this.curInfo = JSON.parse(JSON.stringify(row));
      this.showLandPage = true;
    },
    deleteSelectedPage(id) {
      this.$confirm("确定删除所选内容吗?", "提示!", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delLandPage(id).then(() => {
            this.$message({
              showClose: true,
              message: "删除成功!",
              type: "success",
            });
            this.getData();
          });
        })
        .catch(() => {});
    },
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    resetData() {
      this.params = {};
      this.timeRange = [];
      this.initData();
    },
  },
  components: {
    SelectDeptUser,
    AddLandPage,
    AddRemoteLink,
    ChooseBook,
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-table {
  .is-leaf {
    border-bottom: 0;
  }
}
.origin-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width:150px;
    height:150px;
    margin-bottom:10px;
    margin-right:10px;
  }
}

.operation {
  line-height: 40px;
}
.landing-page-container {
  background: #fff;
  width: calc(100vw - 240px);
  .origin-img {
    img {
      width: 400px;
    }
    video {
      width: 400px;
    }
  }
}

.thumbnail-img {
  width: 64px;
  height: 36px;
  img {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
