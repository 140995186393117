<template>
  <el-drawer
    :title="`${info?'编辑':'新增'}落地页`"
    size="45%"
    append-to-body
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="remote-link" v-if="!info">
      <el-input
        v-model="remoteUrl"
        style="width:60%;margin-right:10px;margin-left:40px"
        placeholder="请输入链接地址"
        ref="input"
        ><i
          class="el-icon-edit el-input__icon"
          slot="suffix"
          @click="handleIconClick"
        >
        </i
      ></el-input>
      <el-button type="primary" @click="getRemoteLink">确定</el-button>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
      <el-form-item label="素材" prop="img">
        <template v-if="this.info">
          <el-button type="primary" @click="uploadImg">上传素材</el-button>
          <input
            @change="handleFileChange"
            type="file"
            style="display:none"
            ref="uploadFile"
          />
        </template>
        <div v-loading="loading">
          <div
            style="display:flex;justify-content:flex-start;align-items:center;flex-wrap:wrap"
            v-if="ruleForm.img.length > 0"
          >
            <div
              class="source-img"
              v-for="(item, index) in ruleForm.img"
              :key="index"
            >
              <img :src="item" alt="" />
              <div class="mask-hover">
                <i
                  class="iconfont icon-lajitong"
                  style="color: #fff"
                  @click="
                    () => {
                      ruleForm.img.splice(index, 1);
                    }
                  "
                ></i>
              </div>
            </div>
          </div>
          <div v-if="!info && ruleForm.img.length === 0" style="color:#969696">
            暂无数据,请在上方输入链接地址获取图片素材!
          </div>
        </div>
      </el-form-item>
      <el-form-item label="书籍">
        <choose-book
          @chooseBookItem="chooseBookItem"
          :show="show"
          :bookInfo="bookInfo"
        ></choose-book>
      </el-form-item>
      <el-form-item label="素材分类">
        <el-select
          v-model="ruleForm.cId"
          placeholder="请选择素材分类"
          filterable
          clearable
        >
          <el-option
            v-for="item in resourceCategoryList"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容">
        <el-input
          type="textarea"
          v-model="ruleForm.content"
           :autosize="{  minRows: 15,maxRows:15}"
          placeholder="请输入内容!"
        ></el-input>
      </el-form-item>
      <el-form-item label="想法">
        <el-input type="textarea" :autosize="{  minRows: 6,maxRows:6}" v-model="ruleForm.idea"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button type="info" @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import {
  addLandPage,
  getRemoteLink,
  updateLandPage,
  compressUpload
} from "@/api/novelPut.js";
import { getClassifyList } from "@/api/classify";
import ChooseBook from "./choose-book.vue";

export default {
  name: "AddLandPage",
  props: ["show", "info"],
  data() {
    return {
      bookId: null,
      bookCategoryId: null,
      bookCategoryList: [],
      resourceCategoryId: null,
      resourceCategoryList: [],
      filterBookList: [],
      bookLoading: false,
      loading: false,
      bookInfo: null,
      remoteUrl: "",
      rules: {
        img: [
          {
            required: true,
            message: "请上传素材!",
            trigger: "blur"
          }
        ]
      },
      ruleForm: {
        img: "",
        content: "",
        idea: "",
        bookId: 0,
        bookCId: 0,
        cId: 0
      }
    };
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          if (this.info) {
            let {
              img,
              content,
              idea,
              bookId,
              bookCId,
              bookName,
              cId
            } = this.info;
            this.ruleForm = {
              img: JSON.parse(img),
              content,
              idea,
              bookId,
              bookCId,
              cId
            };
            this.bookInfo = {
              bookId: bookId ? bookId : 0,
              bookCId: bookCId ? bookCId : 0,
              bookName: this.info.book ? this.info.book.bookName : 0
            };
          } else {
            this.ruleForm = {
              img: [],
              content: "",
              cId: 0,
              idea: "",
              bookCId: 0,
              bookId: 0
            };
            this.bookInfo = null;
          }
        }
        this.getClassifyList();
      }
    }
  },
  mounted() {},
  methods: {
    getRemoteLink() {
      let regExp = /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
      if (!regExp.test(this.remoteUrl)) {
        this.$message({
          showClose: true,
          message: "请输入正确的链接格式!",
          type: "error"
        });
        return;
      }
      this.loading = true;
      getRemoteLink({ link: this.remoteUrl })
        .then(res => {
          this.ruleForm.img = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleIconClick() {
      this.$refs.input.select();
    },
    getClassifyList() {
      getClassifyList({
        type: 5
      }).then(res => {
        res.unshift({
          id: 0,
          name: "所有分类",
          type: 5
        });
        this.resourceCategoryList = res;
      });
    },
    chooseBookItem(item) {
      this.ruleForm.bookId = item.id;
      this.ruleForm.bookCId = item.categoryId;
    },
    handleClose() {
      this.resetData();
      this.$emit("update:show", false);
    },
    resetData() {
      this.ruleForm = {
        img: [],
        content: "",
        cId: null,
        idea: "",
        bookCId: null,
        bookId: null
      };
      this.remoteUrl = "";
      this.bookInfo = null;
    },
    submitForm(refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) {
          this.$message({
            showClose: true,
            message: "信息配置有误!",
            type: "error"
          });
        } else {
          // 如果是新增
          this.ruleForm.cId = this.ruleForm.cId ? this.ruleForm.cId : 0;
          if (!this.info) {
            // this.ruleForm.img.forEach((item, index) => {
            //   arr.push({
            //     ...this.ruleForm,
            //     img: this.ruleForm.img[index]
            //   });
            // });

            addLandPage({ ...this.ruleForm }).then(() => {
              this.$message({
                showClose: true,
                message: "新增成功!",
                type: "success"
              });
              this.handleClose();
              this.$emit("refresh");
            });
          } else {
            // 如果是更新
            let id = this.info.id;

            updateLandPage(id, {
              ...this.ruleForm,
              img: this.ruleForm.img
            }).then(res => {
              this.$message({
                showClose: true,
                message: "更新成功!",
                type: "success"
              });
              this.handleClose();
              this.$emit("refresh");
            });
          }
        }
      });
    },
    uploadImg() {
      this.$refs.uploadFile.click();
    },
    handleFileChange(e) {
      let targetFile = e.target.files[0];
      compressUpload({ file: targetFile })
        .then(res => {
          this.ruleForm.img.push(res);
        })
        .finally(() => {
          this.$refs.uploadFile.value = "";
        });
    }
  },
  components: {
    ChooseBook
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  box-sizing: border-box;
  padding: 0 20px;
}
.choose-bookType {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.source-img {
  margin-top: 10px;
  width: 150px;
  height: 150px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    padding-right: 10px;
  }
  .mask-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  &:hover {
    .mask-hover {
      display: block;
    }
  }
}
.remote-link {
  margin-bottom: 20px;
}
</style>
